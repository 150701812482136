import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  baseUrl=""

  constructor(private http:HttpClient) { }

  getCarByYearMakeModel(year:number,make:string,model:string){
    return this.http.get(`${this.baseUrl}/api/car`,{
      params: {
        "year": year,
        "make":make,
        "model":model
      }
    });
  }

  getWipersByYearMakeModel(year:number,make:string,model:string){
    let query:any = {
      "year": year,
      "make":make,
      "model":model
    }
    
    return this.http.get(`${this.baseUrl}/api/car/wipers`,{
      params: query
    });
  }

  getEngineOptionsByYearMakeModel(year:number,make:string,model:string){
    return this.http.get(`${this.baseUrl}/api/car/engine-options`,{
      params: {
        "year": year,
        "make":make,
        "model":model
      }
    });
  }

  //by convention i'll name query routes where:
  //query is get a request done by a post so we can use the body i guess
  queryWixProductsByIdEngine(car_id:any,engine:string){
    return this.http.post(`${this.baseUrl}/api/car/filters`,{
      "car_id": car_id,
      "engine": engine
    });
  }

  getWixProductsByYearMakeModel(year:number,make:string,model:string){
    let query:any = {
      "year": year,
      "make":make,
      "model":model
    }
    
    return this.http.get(`${this.baseUrl}/api/car/filters`,{
      params: query
    });
  }

  getCarBulbsByYearMakeModel(year:number,make:string,model:string,submodel?:string){
    let query:any = {
      "year": year,
      "make":make,
      "model":model
    }
    if(submodel != undefined){ query["submodel"] = submodel;}
    return this.http.get(`${this.baseUrl}/api/car/bulbs`,{
      params: query
    });
  }
}
