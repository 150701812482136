import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HomeSearchService {

  baseUrl=""

  constructor(private http: HttpClient) { }

  getAllYears(){
    return this.http.get(`${this.baseUrl}/api/search/years`);
  }

  getMakesForYear(year:number){
    return this.http.get(`${this.baseUrl}/api/search/makes`,{
      params: {
        "year": year
      }
    });
  }

  getModelsForYearAndMake(year:number,make:string){
    return this.http.get(`${this.baseUrl}/api/search/models`,{
      params: {
        "year": year,
        "make":make
      }
    });
  }
}
