import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { SeoService } from '../seo.service';
import { HomeSearchService } from './home-search.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  years = new FormControl();
  makes = new FormControl();
  models = new FormControl();

  yearList?: string[];
  makeList: string[] = [];
  modelList: string[] = [];

  constructor(
    private router: Router,
    private homeSearch:HomeSearchService,
    private seoService:SeoService) { }

  ngOnInit(): void {
    this.seoService.updateTitle("Vehicle Repair Info");
    this.seoService.updateDescription("Homepage for vehicle-repair.info")
    this.homeSearch.getAllYears().subscribe(res=>{
      this.yearList = JSON.parse(JSON.stringify(res))
    })
  }

  getMakes(){
    if(this.years.value == undefined) return;
    let year = parseInt(this.years.value)
    
    if(Number.isNaN(year)){
      console.error("Year is not a number")
      return;
    }

    this.homeSearch.getMakesForYear(year).subscribe(res=>{
      this.makeList = JSON.parse(JSON.stringify(res))
    })
  }

  getModels(){
    if(this.years.value == undefined || this.makes.value == undefined) return;
    const year = parseInt(this.years.value)
    const make = this.makes.value
    if(Number.isNaN(year)){
      console.error("Year is not a number")
      return;
    }

    //TODO idk how to validate option was one of the originally provided. Maybe form controls aren't 2-way bound so i can say "if make in makeList?"

    this.homeSearch.getModelsForYearAndMake(year,make).subscribe(res=>{
      this.modelList = JSON.parse(JSON.stringify(res))
    })
  }

  onSubmit(){
    if(this.years.value == undefined || this.makes.value == undefined) return;
    const year = parseInt(this.years.value)
    const make = this.makes.value
    const model = this.models.value
    if(Number.isNaN(year)){
      console.error("Year is not a number")
      return;
    }
    console.log(`submitted ${year} ${make} ${model}`)
    let navigationExtras: NavigationExtras = {
      queryParams: {
        year: year,
        make: make,
        model: model
      }
    };
    this.router.navigate(['/vehicle/details'], navigationExtras);
  }

}
