import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

//https://stackoverflow.com/questions/48330535/dynamically-add-meta-description-based-on-route-in-angular

  constructor(private title: Title, private meta: Meta) { }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  //TODO investigate
  // updateOgUrl(url: string) {
  //   this.meta.updateTag({ name: 'og:url', content: url })
  // }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }
}
