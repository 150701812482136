<div id="all-sections">
    <div class="upper-section">
        <div class="card-summary">
            <mat-card class="example-card">
                <mat-card-header>
                    <mat-card-title>{{year}} {{make}} {{model}}</mat-card-title>
                    <!-- get nthsa vehicle classification -->
                    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
                </mat-card-header>
                <img *ngIf="carDetails?.image_url" mat-card-image src="{{carDetails?.image_url}}"
                    alt="Photo of a {{year}} {{make}} {{model}}">
                <img *ngIf="!carDetails?.image_url" mat-card-image src="/assets/3.png" alt="Placeholder photo">
                <mat-card-content>
                    <p *ngIf="!carDetails?.image_url">Placeholder image, hopefully we'll have an image for you soon!</p>
                    <p *ngIf="carDetails?.image_url">The image above may not exactly match your car based on the
                        submodel or trim of your car</p>
                    <div *ngIf="carDetails?.submodels.length > 0">
                        <p>{{carDetails.submodels}}</p>
                    </div>
                    <p *ngIf="carDetails?.generation">
                        Generation: {{carDetails.generation}}
                    </p>
                </mat-card-content>
                <!-- <mat-card-actions>
      <button mat-button>LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions> -->
            </mat-card>
        </div>
        <div class="choose-car">
            <div id="supplemental-links">
                <p><b>Note: </b>The vehicle links below redirect to VinCheck.info, an unaffiliated and trusted 3rd party
                website. The vehicle details may change based on your vehicle variant, so please use your VIN for precise
                details.</p>
                <div id="vin-check-links" class="actions">
                    <div id="car-details">
                    <a mat-raised-button
                        href="https://vincheck.info/check/vehicle/specifications/{{make}}/{{model}}/{{year}}"
                        color="accent" target="_blank">Vehicle Specifications</a>
                    <a mat-raised-button
                        href="https://vincheck.info/check/vehicle/equipment-details/{{make}}/{{model}}/{{year}}"
                        color="accent" target="_blank">Vehicle Equipment</a>
                    </div>
                    <div id="owners-manual-link" *ngIf="carDetails?.owner_manual_url">
                        <a mat-raised-button href="{{carDetails?.owner_manual_url}}" color="accent">Link out to Owner's
                            Manual
                            Download</a>
                        <p>Issues with the link above? Try <a
                                href="https://justgivemethedamnmanual.com">JustGiveMeTheDamnManual</a>
                        </p>
                    </div>
                    <div *ngIf="!carDetails?.owner_manual_url">
                        <p>We don't have the link to an official manufacturer's owner's manual for your car. <br>
                            You can see if someone has uploaded it here <a
                                href="https://justgivemethedamnmanual.com">JustGiveMeTheDamnManual</a><br>
                            Or if it's available at <a mat-raised-button
                                href="https://vincheck.info/car-manuals/{{make}}/{{year}}-{{make}}-{{model?.replace(' ', '-')}}">VinCheck.info</a>
                        </p>

                    </div>
                </div>
            </div>
            <div id="additional-selection">
                <mat-form-field *ngIf="engines.length>0" appearance="fill">
                    <mat-label>Choose an engine</mat-label>
                    <mat-select (selectionChange)="setEngine($event.value)">
                        <mat-option *ngFor="let engine of engines" value="{{engine}}">{{engine}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="carDetails?.submodels.length>0" appearance="fill">
                    <mat-label>Choose a submodel/trim</mat-label>
                    <mat-select (selectionChange)="setTrim($event.value)">
                        <mat-option *ngFor="let submodel of carDetails?.submodels"
                            value="{{submodel}}">{{submodel}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div id="button-actions">
                <a mat-raised-button href="https://forms.gle/cGLP62sJKhtt4KnQ7" target="_blank">
                    <span class="material-symbols-outlined">
                        report
                    </span>
                    Report Issue with Vehicle
                </a>
            </div>
        </div>
    </div>
    <div class="lower-section">
        <mat-tab-group mat-align-tabs="center">
            <mat-tab label="Filters (Oil, Air, etc)">
                <div class="tab-container">
                    <div class="wix-filters">
                        <div *ngIf="filterResponse?.engine">
                            For Engine: {{filterResponse.engine}}
                            <p>Wix Products <span class="material-symbols-outlined why-wix" #tooltip="matTooltip"
                                    matTooltip="Wix is the filter most used by our team, as well as widely available in box stores. It, particularly the XP variant, has a reputation for being high quality"
                                    [matTooltipPosition]="position.value" matTooltipHideDelay="500"
                                    aria-label="Button that displays a tooltip that hides when scrolled out of the container">help</span>:
                            </p>
                            <ul>

                                <li *ngIf="filterResponse?.filters.oil_filters">
                                    Wix Oil Filter: {{filterResponse?.filters.oil_filters}}
                                </li>
                                <li *ngIf="filterResponse?.filters.fuel_filters">
                                    Wix Fuel Filter: {{filterResponse?.filters.fuel_filters}}
                                </li>
                                <li *ngIf="filterResponse?.filters.air_filters">
                                    Wix Engine Air Filter: {{filterResponse?.filters.air_filters}}
                                </li>
                                <li *ngIf="filterResponse?.filters.cabin_air_filters">
                                    Wix Cabin Air Filter: {{filterResponse?.filters.cabin_air_filters}}
                                </li>
                                <li *ngIf="filterResponse?.filters.transmission_filter">
                                    Wix Transmission Filter: {{filterResponse?.filters.transmission_filter}}
                                </li>
                                <li *ngIf="filterResponse?.filters.breather_filter">
                                    Wix Breather Filter: {{filterResponse?.filters.breather_filter}}
                                </li>
                                <li *ngIf="filterResponse?.filters.hydraulic_filter">
                                    Wix Hydraulic Filter: {{filterResponse?.filters.hydraulic_filter}}
                                </li>
                            </ul>
                        </div>
                        <p *ngIf="!filterResponse?.engine">
                            Please select an engine option from above. If there are no available engine options, we're
                            working
                            on it and
                            hope to have your vehicle's engine available soon!
                        </p>
                        <div class="actions">
                            <a mat-raised-button href="/repair/engine-oil" color="accent">How to Change Oil</a>
                            <a mat-raised-button href="/product/engine-oil" color="accent">Which Oil is Best?</a>
                        </div>
                        <div *ngIf="carDetails?.oil_link">
                            <p>For information on which oil to use and how much would be required check out OilType.co
                            </p>
                            <a mat-raised-button href="{{carDetails?.oil_link}}" color="accent">Go to OilType.co</a>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Car Bulbs">
                <div class="tab-container">
                    <mat-form-field appearance="standard">
                        <mat-label>Filter Bulb Table</mat-label>
                        <input matInput (keyup)="applyBulbFilter($event)" placeholder="Ex. Mia" #input>
                    </mat-form-field>
                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="bulbDataSource" matSort>

                            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

                            <!-- Position Column -->
                            <ng-container matColumnDef="position">
                                <th mat-header-cell *matHeaderCellDef> Bulb Position </th>
                                <td mat-cell *matCellDef="let element"> {{element.position}} </td>
                            </ng-container>

                            <!-- Number Column -->
                            <ng-container matColumnDef="number">
                                <th mat-header-cell *matHeaderCellDef> Product Number </th>
                                <td mat-cell *matCellDef="let element"> {{element.product_number}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="bulbDisplayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: bulbDisplayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"
                            aria-label="Select page of users"></mat-paginator>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Car Wipers">
                <div class="tab-container">
                    <div *ngIf="wipers.length >0" id="wiper-list">
                        <ul>
                            <li *ngFor="let wiper of wipers">
                                {{formatWiper(wiper)}}
                            </li>
                        </ul>
                        <p>See a comparison of the best wipers and their prices on the wiper product page!</p>
                        <a mat-raised-button href="/product/wiper-blades" color="accent">Wiper Blades</a>
                    </div>
                    <div *ngIf="wipers.length == 0">
                        <p> Sorry it seems we don't have wiper data for this vehicle. Please notify us using the report
                            issue button above!</p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>