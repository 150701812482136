<div class="main">
    <div id="car-search-div">
        <form #carForm="ngForm" (ngSubmit)="onSubmit()">
            <div class="dropdowns">
                <mat-form-field appearance="fill" style="flex: 1;">
                    <mat-label>Year</mat-label>
                    <mat-select [formControl]="years" (selectionChange)="getMakes()" required>
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let year of yearList" [value]="year">{{year}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="makeList.length > 0" appearance="fill" style="flex: 1;">
                    <mat-label>Make</mat-label>
                    <mat-select [formControl]="makes" (selectionChange)="getModels()" required>
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let make of makeList" [value]="make">{{make}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="modelList.length > 0" appearance="fill" style="flex: 1;">
                    <mat-label>Model</mat-label>
                    <mat-select [formControl]="models" required>
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let model of modelList" [value]="model">{{model}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="submit-button">
                <button mat-stroked-button [disabled]="!carForm.valid">Submit</button>
            </div>
        </form>
    </div>
    
    <div id="about">
        <h2>About</h2>
        <p>
            We made this website because we think the automotive industry sucks at making car data and repair
            information accessible. This will hopefully become a website that gives you a "one-stop shop" where you can bookmark your car's page and quickly reference the parts/specifications for simple repairs. Development is ongoing.
        </p>
        <p>
            We also have information about common maintenance procedures and repairs, and link out to many resources we've personally used in our own car repair journey.
        </p>
        <p>
            Lastly, this website is part of the Amazon Associate program so some links would earn a commission. This is not a product endorsement, and we encourage you to shop around for the best available deal or comparable product.
        </p>
    </div>
</div>