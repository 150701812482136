import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VehicleService } from '../vehicle.service';

import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {TooltipPosition} from '@angular/material/tooltip';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit{
  year?: number;
  make?: string;
  model?: string;
  wipers:any=[];
  engines:any=[];
  positionOptions: TooltipPosition[] = ['below', 'above', 'left', 'right'];
  position = new FormControl(this.positionOptions[1]);

  @ViewChild(MatPaginator) paginator?: MatPaginator;
  @ViewChild(MatSort) sort?: MatSort;
  bulbDisplayedColumns: string[] = ['position', 'number'];
  bulbDataSource!: MatTableDataSource<never>;

  carDetails: any;
  bulbList = [];
  filterResponse: any;

  constructor(
    private route: ActivatedRoute,
    private vehicleService: VehicleService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.year = parseInt(params["year"]);
      this.make = params["make"];
      this.model = params["model"];
      this.vehicleService.getCarByYearMakeModel(this.year, this.make!, this.model!).subscribe(res => {
        this.carDetails = JSON.parse(JSON.stringify(res))
        this.getCarWipers(this.year!,this.make!,this.model!)
        this.getEngineOptions(this.year!,this.make!,this.model!)
        this.getCarBulbs(this.year!,this.make!,this.model!)
      })

    });
  }

  async getCarWipers(year:number, make:string, model:string){
    this.vehicleService.getWipersByYearMakeModel(year,make,model).subscribe((res)=>{
      this.wipers = JSON.parse(JSON.stringify(res));
    })
  }

  async getEngineOptions(year:number, make:string, model:string){
    this.vehicleService.getEngineOptionsByYearMakeModel(year,make,model).subscribe((res)=>{
      this.engines = JSON.parse(JSON.stringify(res));
    })
  }

  getCarBulbs(year: number, make: string, model: string, submodel?:string) {
    this.vehicleService.getCarBulbsByYearMakeModel(year, make, model,submodel).subscribe(res => {
      this.bulbList = JSON.parse(JSON.stringify(res))
      this.bulbDataSource = new MatTableDataSource(this.bulbList);
      console.log("after bulb set")
      this.bulbDataSource.paginator = this.paginator || null;
      this.bulbDataSource.sort = this.sort || null;
    })
  }

  applyBulbFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue)
    this.bulbDataSource.filter = filterValue.trim().toLowerCase();

    if (this.bulbDataSource.paginator) {
      this.bulbDataSource.paginator.firstPage();
    }
  }

  goToUrl(url:string){
    window.open(url, "_blank");
  }

  setEngine(engine:string){
    this.vehicleService.queryWixProductsByIdEngine(this.carDetails["_id"],engine).subscribe(res=>{
      //TODO idk what this will do if there is no filter available
      this.filterResponse = JSON.parse(JSON.stringify(res))
    })
  }

  setTrim(trim:string){
    this.getCarBulbs(this.year!,this.make!,this.model!,trim)
  }

  formatWiper(wiper:any){
    let wiper_pos_key:any = {"D":"Driver","M":"Middle","P":"Passenger","R":"Rear"}
    return `${wiper_pos_key[wiper["position"]]} - ${wiper["blade_size_inches"]} inches`
  }

}
