import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isProductsOpen:boolean = false;
  isRepairsOpen:boolean=false;

  mobile:boolean=false;

  constructor(private router:Router) { }

  ngOnInit(): void {
    if (window.screen.width < 500) { // 768px portrait
      this.mobile = true;
    }
  }

  goToRepairs(){
    console.log("go to repairs")
  }

}
