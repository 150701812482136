<mat-toolbar *ngIf="!mobile" color="primary" style="height:7vh;">

    <div class="header-button">
        <a mat-button class="header-link" href="home" style=>Vehicle-Repair.info</a>
    </div>

    <div class="header-button">
        <a mat-button class="header-link" href="about"> About </a>
    </div>

    <div class="header-button">
        <a mat-button class="header-link" href="repair"> Repairs </a>
    </div>

    <div class="header-button">
        <a mat-button class="header-link" href="product"> Products </a>
    </div>

    <!-- <a class="toolbar-link" (click)="goToRepairs()">
        <div class="header-dropdown" (mouseenter)="isRepairsOpen=true" (mouseleave)="isRepairsOpen=false">
            <div class="top-level-text">
            <span>Repairs</span>
            <span *ngIf="!isRepairsOpen" class="material-symbols-outlined"> expand_more</span>
            <span *ngIf="isRepairsOpen" class="material-symbols-outlined"> expand_less</span>
            </div>
            <div class="header-dropdown-items" *ngIf="isRepairsOpen">
                <ul>
                    <li><a href="#bulbs">Bulbs</a></li>
                    <li><a href="#filters">Filters</a></li>
                </ul>
            </div>
        </div>
    </a> -->

    <!-- <a class="toolbar-link" (click)="goToRepairs()">
        <div class="header-dropdown" (mouseenter)="isProductsOpen=true" (mouseleave)="isProductsOpen=false">
            <div class="top-level-text">
            <span>Products</span>
            <span *ngIf="!isProductsOpen" class="material-symbols-outlined"> expand_more</span>
            <span *ngIf="isProductsOpen" class="material-symbols-outlined"> expand_less</span>
            </div>
            <div class="header-dropdown-items" *ngIf="isProductsOpen">
                <ul>
                    <li><a href="#bulbs">Bulbs</a></li>
                    <li><a href="#filters">Filters</a></li>
                </ul>
            </div>
        </div>
    </a> -->
    <span class="spacer"></span>
    <div class="header-button">
        <a mat-button class="header-link" href="https://forms.gle/GDnrAyAbzHEKTgY17" target="_blank"> Contact Us </a>
    </div>
</mat-toolbar>

<mat-toolbar *ngIf="mobile" color="primary" style="height:7vh;">

    <div class="header-button">
        <a mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
        </a>
    </div>
    <mat-menu #menu="matMenu">
        <a mat-menu-item href="about"> About </a>
        <a mat-menu-item href="repair"> Repairs </a>
        <a mat-menu-item href="product"> Products </a>
    </mat-menu>

    <div class="header-button">
        <a mat-button class="header-link" href="home">Vehicle-Repair.info</a>
    </div>

    <!-- <a class="toolbar-link" (click)="goToRepairs()">
        <div class="header-dropdown" (mouseenter)="isRepairsOpen=true" (mouseleave)="isRepairsOpen=false">
            <div class="top-level-text">
            <span>Repairs</span>
            <span *ngIf="!isRepairsOpen" class="material-symbols-outlined"> expand_more</span>
            <span *ngIf="isRepairsOpen" class="material-symbols-outlined"> expand_less</span>
            </div>
            <div class="header-dropdown-items" *ngIf="isRepairsOpen">
                <ul>
                    <li><a href="#bulbs">Bulbs</a></li>
                    <li><a href="#filters">Filters</a></li>
                </ul>
            </div>
        </div>
    </a> -->

    <!-- <a class="toolbar-link" (click)="goToRepairs()">
        <div class="header-dropdown" (mouseenter)="isProductsOpen=true" (mouseleave)="isProductsOpen=false">
            <div class="top-level-text">
            <span>Products</span>
            <span *ngIf="!isProductsOpen" class="material-symbols-outlined"> expand_more</span>
            <span *ngIf="isProductsOpen" class="material-symbols-outlined"> expand_less</span>
            </div>
            <div class="header-dropdown-items" *ngIf="isProductsOpen">
                <ul>
                    <li><a href="#bulbs">Bulbs</a></li>
                    <li><a href="#filters">Filters</a></li>
                </ul>
            </div>
        </div>
    </a> -->
    <span class="spacer"></span>
    <div class="header-button">
        <a mat-button class="header-link" href="https://forms.gle/GDnrAyAbzHEKTgY17" target="_blank"> Contact Us </a>
    </div>
</mat-toolbar>